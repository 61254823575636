import React from 'react'
import "../../assets/scss/style.scss";
import customerSupportSVG from '../../assets/images/customer-support.svg'

export const CevraFooter = () => {
    return (
        <footer className='footer' style={{ background: 'white' }}>
            <div className={window.location.hash.includes('auth') ? 'row center' : 'row center footer-font'} style={{ maxWidth: '100%' }}>
                <div className={window.location.hash.includes('auth') ? 'col-12' : 'col-6 mt-2'}>
                    <a href='mailto:info@cevra.es' className={window.location.hash.includes('auth') ? 'text-muted mr-2' : 'text-muted footer-font'}>
                        <i className="fa-solid fa-envelope mr-2" style={{ fontStyle: "normal", color: '#ff423d' }} />info@cevra.es
                    </a>
                    <br className='for-mobile' />
                    <a className={window.location.hash.includes('auth') ? 'text-muted' : 'text-muted footer-font ml-2'} type='tel' href='tel:+34633109300'><i className="fa-solid fa-phone mr-2" style={{ fontStyle: "normal", color: '#00be8c' }} />+34-633109300</a>
                </div>
                <p className={window.location.hash.includes('auth') ? 'bold text-muted mb-1 mr-1' : 'bold text-muted footer-font m-2'}><img className='mr-2' style={{ width: '25px' }} src={customerSupportSVG} alt="customer" /> 24 x 7 Customer support</p>
                <p className={window.location.hash.includes('auth') ? 'bold text-muted mb-1' : 'bold text-muted footer-font m-2'}>&copy; Cevrapp Prototype-3 {new Date().getFullYear()}</p>
            </div>
        </footer>
    )
}
